<template>
  <div>
    <PageTopSection title="Terms & Conditions" />
    <section class="blog-details">
        <div class="container">
          <div class="row">
            <div class="col-lg">
              <div class="blog-details__content">
                <!-- /image -->
                    <h1 style="text-align: center; line-height: 107%; break-after: avoid; font-size: 16pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;" align="center"><strong><span style="font-size: 26.0pt; line-height: 107%; font-family: Arial, sans-serif;">Respectmart Terms and Conditions</span></strong></h1>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Introduction</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Please read and examine the following Terms and Conditions before you use this website to learn the rules that guide our platform, mobile app, services, and operations. “We,” “our,” and “us,” as used in this document, refer to Respectmart, the parent company, and its affiliates. </span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Please, note that these Terms and Conditions are subject to change without informing you beforehand. To be aware of such changes, we recommend you review the Terms and Conditions and understand them whenever you visit this website and use the mobile app. </span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">If you are below 18 years, you have to obtain permission, authorization, and consent from your parents or guardians in advance to be bound by these Terms and Conditions before using this site or any of our services. If you are not 18 years yet and have not obtained your parents’ or guardians’ consent, you may not use this site and our services.&nbsp; </span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Who are we? </span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Respectmart, with the website <span style="color: blue; text-decoration: underline;"><a style="color: blue; text-decoration: underline;" href="https://respectmart.com/">https://respectmart.com/</a></span>, is a subsidiary of Afridext Integrated Services LLC., a registered firm in Nigeria. </span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">How can you contact us?</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">You can contact our reps via email at <span style="color: blue; text-decoration: underline;"><a style="color: blue; text-decoration: underline;" href="mailto:info@respectmart.com">info@respectmart.com</a></span> or phone call at +1(312)-420-3967.</span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Consent to our Terms</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">By making use of our website, you have confirmed your consent to our Terms and will obey the guidelines, use policy, rules, and Terms of our services. If you’re acting as a representative of an entity, these Terms are also binding on them.</span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Withdrawal or suspension of parts or all of the website</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We reserve the right to withdraw or suspend parts or all of our site for business and operational purposes. If we will be withdrawing or suspending any or all of the Content on our site, we’ll notify you.</span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We believe that it is your responsibility to make sure all individuals that use this website via your Internet connection know about and comply with these Terms and Conditions (including the other related terms mentioned above).&nbsp; </span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Transfer of these Terms to another party</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We may transfer parts or all of the rights and obligations under this agreement to another party, and in case that happens, we will always inform you by writing. Also, we will ensure that this arrangement does not affect your rights under the agreement. </span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Change of Terms</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">This website — just like our Terms — are subject to change whenever necessary without any prior notice to you. Apart from these terms, we also have other related terms (our acceptable Use Policy and our Privacy Policy) that may also be binding on you. </span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">If you continue using the site after we made such a change or changes, you give consent to comply with these modified Terms and Conditions. Whenever you use this website, consider reviewing these Terms to learn of any changes made to it. &nbsp;</span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">This Content is our intellectual property</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">All or part of the Content — written and in any other form — on this website is owned by Afridext Integrated Services LLC and its subsidiary Respectmart or other third parties affiliated with us. The collection of this Content, including its arrangement and assembly, is exclusively our intellectual property. </span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Respectmart and its suppliers and affiliates reserve expressly all intellectual property rights in all of the Content on this site. This Content is provided for only personal, non-commercial use. </span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Therefore, you may not distribute, copy, download, post, create, republish, or display works derivative of or from the Content, whether in a written form or any other forms or by any means except by written permission of Respectmart or its parent company, Afridext. In case of such consent, we have not given you any title, interest, or right in any downloaded content or any other materials.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;<a name="intellectual-property"></a></span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Responsibility to ensure the safety of your account</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">You must keep your account details safe at all times. As part of our security measures, if we give you or you choose a password, passcode, or other such information, it’s your responsibility to keep such information safe always. You must keep such details away from third parties.</span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We reserve the right to deactivate any password, passcode, or user identification code at any time, whether of your own making or given to you by us if we believe you have not complied with any of our provisions as stated in our Terms and Conditions. If you feel or suspect a third party knows your password, passcode, or user identification code, you have to inform us immediately. </span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">We do not allow web scrapping, data, or text mining &nbsp;&nbsp;</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">You shall not carry out or facilitate web scrapping, text, or data mining on our site, in relation to this website or our services. Concerning this prohibition is attempting to use or using the following:</span></p>
                    <ul style="margin-bottom: 0in; margin-top: 0px;">
                        <li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">A bot, robot, scraper software, spider, or such automated devices, tools, codes, programs/software, algorithm, or technique, to gain access to, copy, obtain, reproduce, or monitor any part of our website, Content, or services.&nbsp; &nbsp;&nbsp;&nbsp;</span></li>
                        <li style="margin: 0in 0in 8pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">An automated analytical method for analyzing data, information, and text in digital form for producing Content that includes trends, patterns, and others. </span></li>
                    </ul>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Content monitoring </span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We do not control, monitor, edit, or filter data and Content our users submit to this website. Such information, which may include Content found on other sites, blogs, forums, and chat groups, does not represent our views and opinions. We have not endorsed the information. You shall not regard such Content as professional advice of any type, and if you have a complaint about the Content given by or the activities of any user of our site, kindly inform us promptly. </span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Inaccurate information and cancellations</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">As the information and Content on our website may be inaccurate, contain errors, or be out-of-date, we reserve the right to modify or update the information at any time to correct inaccuracies, errors, or omissions without any prior notice to you. This Condition also includes after you have submitted an order. Kindly remember that such omissions, inaccuracies, or errors may have to do with product description, product details, product image, pricing, and availability. </span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We reserve the right to cancel, reject, or change orders or parts of orders you place via our website. For orders you paid via respectmart.com, if we have already deducted the cost from your credit card and the order is canceled, modified, or rejected, kindly contact Respectmart Customer Care at +1(312)-420-3967. We will refund you. &nbsp;&nbsp;</span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">We are not responsible for sites we are affiliated with or linked to </span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">If you find links to other sites and resources from third parties on our website, they are provided only as general information. Do not misinterpret such links or the information you get from them as an approval or endorsement from us. We do not have and exercise any control over the contents of resources from the third-party sites, and all arrangements concerning such resources or links are to be made with the third parties (suppliers). &nbsp;&nbsp;</span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Responsibility for loss</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Please note the following whether you are a business user or consumer:</span></p>
                    <ul style="margin-bottom: 0in; margin-top: 0px;">
                        <li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We do not, in any way, limit or exclude our liability to you where doing so would be unlawful. This condition includes liability for personal injury or death due to our negligence or that of our staff or subcontractors.</span></li>
                        <li style="margin: 0in 0in 8pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We will apply different exclusions and limitations of liability to liability that arises from the supply of our products or services as stated in the terms and conditions for such particular products and services </span></li>
                    </ul>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">For a consumer user: </span></p>
                    <ul style="margin-bottom: 0in; margin-top: 0px;">
                        <li style="margin: 0in 0in 8pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Remember that our website is only for private and domestic use. You give your consent not to make use of our website for business or commercial purposes, and we do not have any liability to you for loss of business, loss of profit, loss of business, or business disruption. </span></li>
                    </ul>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">For a business or commercial user: </span></p>
                    <ul style="margin-bottom: 0in; margin-top: 0px;">
                        <li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We exclude all implied conditions, warranties, or other terms which could be applicable to our website or any content on the site. </span></li>
                        <li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We will not have any liability to you for any damage or loss that occurs in contract, breach of statutory duty, tort (in addition to negligence), or others, even if expected which comes up concerning</span>
                            <ul style="list-style-type: circle; margin-bottom: 0in; margin-top: 0px;">
                                <li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">using or relying on any content or information provided on our website</span></li>
                                <li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">using, or lack thereof, our website </span></li>
                            </ul>
                        </li>
                        <li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Specifically, we will not have any liability for </span></li>
                    </ul>
                    <ul style="list-style-type: circle; margin-bottom: 0in; margin-top: 0px;">
                        <li style="margin: 0in 0in 0.0001pt 53px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">business disruption</span></li>
                        <li style="margin: 0in 0in 0.0001pt 53px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">loss of sales, business, revenue, or profit</span></li>
                        <li style="margin: 0in 0in 0.0001pt 53px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">consequential or indirect damage/loss</span></li>
                        <li style="margin: 0in 0in 8pt 53px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">loss of anticipated savings</span></li>
                    </ul>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Download and use of data on our site</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">The download and use of information from this our website are done at your own discretion. Note that data obtained from our site could contain viruses or malware that may harm your computer. We bear no responsibility for any harm or damage data from our website causes to your computer system, and it is your responsibility to implement appropriate protection mechanisms for safeguarding your computer from such harm.&nbsp; </span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">How may we make use of your personal details and information?</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Our use of your personal details will only be as per our <span style="color: blue; text-decoration: underline;"><a style="color: blue; text-decoration: underline;" href="https://sell.respectmart.com/privacy-policy.html">Privacy Policy</a></span>. </span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Content upload to the site</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Our use of your personal details will be as per our <span style="color: blue; text-decoration: underline;"><a style="color: blue; text-decoration: underline;" href="https://sell.respectmart.com/terms.html">Acceptable Use Policy</a></span>. </span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We will regard any content you upload to our website as non-proprietary and non-confidential. While you retain all ownership rights to any content you uploaded on our website, it is required that you provide us with a limited license to make use of, store, and copy the uploaded Content and to also share it with others as necessary for our services. </span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Also, it is within our rights to disclose your identity to any third party that states any content you uploaded on our website violates their right to privacy or their intellectual property rights. It is your sole responsibility to keep safe and secure and back up any content you uploaded or posted on our site. </span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Data privacy and use of your data</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We are fully committed to protecting your privacy and will collate all data you provide for us with which to offer you services. We may use such information to personalize your use of our website. </span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Also, we may make use of this data to let you, the user, know about any modifications to our services, website, and other features that we feel would interest you. We may grant permission to parties affiliated with our company and Afridext to let you know about products and services they provide which might be of interest to you.&nbsp;&nbsp; &nbsp;</span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">By providing this information for us, you agree that we use it for these purposes and for those stated in our Privacy Policy, in addition to the purpose of working on your requests and orders. To learn more about the way we treat your personal data, please check our Privacy Policy.&nbsp; &nbsp;</span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Securing our systems </span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">It is our obligation to protect our enterprise and secure our systems. Hence, remember that we may document and monitor any communication you establish to and fro this site. We may also make use of, publish, and disclose such communications during any legal proceedings.</span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Rules about linking to this website</span></strong></h2>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We allow you to link to our site if you wish to do so. But you must link to our homepage fairly and legally, in a manner that does not bring disrepute to or exploit our company. You must not link to our site in such a manner that suggests some form of affiliation, approval, or association on our part unless such exists. &nbsp;</span></p>
                    <p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">You must not link to this website on a site not possessed by you. This website must not be framed on another website, and you may not link any part of our website to any other site except our homepage. It is within our right to withdraw linking permission without informing you beforehand. The site on which you are linking has to abide by all the standards of our Content as laid out in our Acceptable Use Policy. If you want to link to or use any content on this website other than as stipulated above, kindly get in touch with us at&nbsp; info@respectmart.com. </span></p>
                    <br>
                    <h2 style="margin: 2pt 0in 12pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Country’s laws that apply to disputes</span></strong><br><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif; color: windowtext;">If you are a consumer user, kindly bear in mind that these Terms, all their components, and formation are governed by Nigerian law. Both we, Respectmart, and you, the user, agree Nigerian courts will have exclusive jurisdiction. </span></h2>
                    <br>
                    <h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif; color: windowtext;">If you are a business or commercial user, these Terms, all their components, and formation (including any non-contractual claims or disputes) are governed by Nigerian law. Both we, Respectmart, and you, the user, agree Nigerian courts will have exclusive jurisdiction.</span></h2>

              </div>
            </div>
            <!-- /.col-lg -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container -->
      </section>
  </div>
</template>

<script>

  import PageTopSection from '/src/components/sections/PageTopSection.vue'
  export default {
    name: 'Terms',
    metaInfo: function() {
        return {
            title: "Terms & Conditions | Respectmart",
        }
    },
    data() {
      return {
        PageTopSection
      };
    },

    components: {
        PageTopSection
    },

  }
</script>
